













import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        DroughtCharts: () =>
            import("@/pages/components/droughtIndices/DroughtCharts.vue"),
        DroughtTables: () =>
            import("@/pages/components/droughtIndices/DroughtTables.vue"),
        DroughtMaps: () =>
            import("@/pages/components/droughtIndices/DroughtMaps.vue")
    }
})
export default class DroughtIndices extends Vue {}
